<template>
  <section class="full-height wa-color">
    <img class="wa-logoTop" src="/img/wa_logobig.png" alt="WaarneemApp">
    
    <div class="columns is-centered is-gapless is-marginless">      
      <div class="column is-mobile is-narrow mt2rem mb2rem">        
        <div class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title"> Monitor servers</div>
        <b-switch v-model="enablemonitoring" @input="changeautomonitor">auto monitor</b-switch>
        
      </div>
    </div>
    
    
    <div>
      <div class="columns is-marginless">
        <div class="column is-12">    
            <div class="columns is-gapless is-marginless is-centered is-mobile">
              <div class="column is-narrow is-mobile">
                <div class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined" >Load balancer</div>
                <a :href="SITE_ROOT" target='_blank'>{{ SITE_ROOT }}</a>
              </div>
            </div>
        </div>
      </div>
      
      <div class="is-mobile is-gapless is-centered is-multiline m2rem">
        <b-table
            :data="FilterServerData(['LOADBALANCER'])"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            
            :show-detail-icon="true"
            detailed
            detail-key="name"
            
            :mobile-cards="true">

            <b-table-column field="name" label="Name" width="100" v-slot="props">
                {{ props.row.name }}
                <img class="wa-logo" src="/img/wa_logo.png" alt="WaarneemApp">
            </b-table-column>
            
            <b-table-column field="cpu" label="cpu" width="100" v-slot="props">
                <div>
                  cpu-s: {{ props.row.data.stats.cpu.load[0] }}
                  <b-progress :value="props.row.data.stats.cpu.load[0]" :max="props.row.data.stats.cpu.count/4" type="is-danger"></b-progress>
                </div>
                <div>
                  cpu-m: {{ props.row.data.stats.cpu.load[1] }}
                  <b-progress :value="props.row.data.stats.cpu.load[1]" :max="props.row.data.stats.cpu.count/4" type="is-danger"></b-progress>
                </div>
                <div>
                  cpu-l: {{ props.row.data.stats.cpu.load[2] }}
                  <b-progress :value="props.row.data.stats.cpu.load[2]" :max="props.row.data.stats.cpu.count/4" type="is-danger"></b-progress>
                </div>
                <div>
                  memory: {{ Math.round((props.row.data.stats.memory.total - props.row.data.stats.memory.free) / (1024*1024*1024)) }}GB / {{ Math.round(props.row.data.stats.memory.total / (1024*1024*1024)) }}GB
                  <b-progress :value="props.row.data.stats.memory.total - props.row.data.stats.memory.free" :max="props.row.data.stats.memory.total" type="is-danger"></b-progress>
                </div>
            </b-table-column>
            
            <b-table-column field="health" label="health" width="100" v-slot="props">
              <div>
                <div :class="(props.row.data.systemStats.APTGET > 15) ? 'hasError' : ''">
                  <div class='itemtext'>apt-get:</div> 
                  {{ props.row.data.systemStats.APTGET }} open package(s)
                </div>
                
                <div :class="(mainhealth.laravel.error !== '' || mainhealth.laravel.duration > 1000) ? 'hasError' : ''">
                  <div class='itemtext clickable' @click='checkMainLaravel(true)'>laravel:</div> 
                  <a class='clickable' :href='`${SITE_ROOT}/api/health/laravel`' target='_blank'>
                    {{ mainhealth.laravel.status === 'fetching' ? '-----' : `${mainhealth.laravel.duration}ms` }}
                  </a>
                </div>
                
                <div :class="(mainhealth.database.error !== '' || mainhealth.database.duration > 1000) ? 'hasError' : ''">
                  <div class='itemtext clickable' @click='checkMainDatabase(true)'>database:</div> 
                  <a class='clickable' :href='`${SITE_ROOT}/api/health/database`' target='_blank'>
                    {{ mainhealth.database.status === 'fetching' ? '-----' : `${mainhealth.database.duration}ms` }}
                  </a>
                </div>
                
                <div :class="(mainhealth.redis.error !== '' || mainhealth.redis.duration > 1000) ? 'hasError' : ''">
                  <div class='itemtext clickable' @click='checkMainRedis(true)'>redis:</div>
                  <a class='clickable' :href='`${SITE_ROOT}/api/health/redis`' target='_blank'>
                    {{ mainhealth.redis.status === 'fetching' ? '-----' : `${mainhealth.redis.duration}ms` }}
                  </a>
                </div>
                
                <div :class="(mainhealth.queue.error !== '' || mainhealth.queue.duration > 1000) ? 'hasError' : ''">
                  <div class='itemtext clickable' @click='checkMainQueue(true)'>queue:</div>
                  <a class='clickable' :href='`${SITE_ROOT}/api/health/queue`' target='_blank'>
                    {{ mainhealth.queue.status === 'fetching' ? '-----' : `${mainhealth.queue.duration}ms - ${mainhealth.queue.response ? mainhealth.queue.response.failed_jobs : '?'} failed` }}
                  </a>
                </div>
                
                <div :class="(mainhealth.nuxt.error !== '' || mainhealth.nuxt.duration > 50000) ? 'hasError' : ''">
                  <div class='itemtext clickable' @click='checkMainNuxt(true)'>nuxt:</div>
                  <a class='clickable' :href='`${SITE_ROOT}/`' target='_blank'>
                    {{ mainhealth.nuxt.status === 'fetching' ? '-----' : `${mainhealth.nuxt.duration}ms` }}
                  </a>
                </div>
                
                <div v-if="props.row.name === 'WaProduction|46-4-225-198'" :class="(mainhealth.pusher.error !== '' || mainhealth.pusher.duration > 60000) ? 'hasError' : ''">
                  <div class='itemtext clickable' @click="checkPusher(props.row, true)">pusher:</div>
                  <div>
                    {{ mainhealth.pusher.status === 'fetching' ? '-----' : `${mainhealth.pusher.duration}ms` }}
                  </div>
                  <br>
                  {{ mainhealth.pusher.response ? mainhealth.pusher.response : ' ' }}
                  {{ mainhealth.pusher.error ? mainhealth.pusher.error : ' ' }}
                </div>
              </div>
            </b-table-column>
            
            <b-table-column field="actions" label="actions" width="100" v-slot="props">
              <div>
                <b-button class="m5" @click="BTN_loadbalancerAction(props.row, 'loadbalancer_default')">Normal mode</b-button>
                <br>
                <b-button class="m5" @click="BTN_loadbalancerAction(props.row, 'loadbalancer_panicmode1')">Custom HTML mode</b-button>
                <b-button class="m5" @click="BTN_loadbalancerSetFallbackSite(props.row)">Set Custom HTML</b-button>
                <br>
                <!-- <b-button class="m5" @click="BTN_loadbalancerAction(props.row, 'loadbalancer_panicmode2')">Webinar Page mode</b-button>
                <b-button class="m5" @click="BTN_loadbalancerSetFallbackJSON(props.row)">Set Webinar JSON</b-button> -->
                <br>
                <a class='clickable' :href='`${SITE_ROOT}/`' target='_blank'>
                  view: {{ MAINHOST }}
                </a>
              </div>
            </b-table-column>
            
            <template #detail="props">
              <div :key="`${props.row.name}_${LastUpdated}`">
                <div v-if="props.row.data.log">
                  <div v-for="(log, lindex) in props.row.data.loglist" v-bind:key="`${props.row.name}_${lindex}`">  
                    <div :class="log.code === 'stderrdata' ? 'hasError' : ''">
                      {{ log.data }}
                    </div>
                  </div>
                </div>
              </div>
            </template>

        </b-table>
      </div>
    </div>
    
    
    
    <div>
      <div class="columns is-marginless">
        <div class="column is-12">    
            <div class="columns is-gapless is-marginless is-centered is-mobile">
              <div class="column is-narrow is-mobile">
                <div class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined" >Laravel - Nuxt</div>                
              </div>
            </div>
        </div>
      </div>
      
      <div class="is-mobile is-gapless is-centered is-multiline m2rem">
        <b-table
            :data="FilterServerData(['LARAVELNUXT'])"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            
            :show-detail-icon="true"
            detailed
            detail-key="name"
            
            :mobile-cards="true">

            <b-table-column field="active" label="active" width="15" v-slot="props">
              <div v-if="props.row.LB_EnabledToggle !== null" class="control">
                  <b-switch v-model="props.row.LB_EnabledToggle" @input='ToggleServerLBEnabled(props.row)'></b-switch>
              </div>
            </b-table-column>

            <b-table-column field="name" label="Name" width="100" v-slot="props">
                {{ props.row.name }}
                <img class="wa-logo" src="/img/wa_logo.png" alt="WaarneemApp">
            </b-table-column>
            
            <b-table-column field="cpu" label="cpu" width="100" v-slot="props">
                <div>
                  cpu-s: {{ props.row.data.stats.cpu.load[0] }}
                  <b-progress :value="props.row.data.stats.cpu.load[0]" :max="props.row.data.stats.cpu.count/4" type="is-danger"></b-progress>
                </div>
                <div>
                  cpu-m: {{ props.row.data.stats.cpu.load[1] }}
                  <b-progress :value="props.row.data.stats.cpu.load[1]" :max="props.row.data.stats.cpu.count/4" type="is-danger"></b-progress>
                </div>
                <div>
                  cpu-l: {{ props.row.data.stats.cpu.load[2] }}
                  <b-progress :value="props.row.data.stats.cpu.load[2]" :max="props.row.data.stats.cpu.count/4" type="is-danger"></b-progress>
                </div>
                <div>
                  memory: {{ Math.round((props.row.data.stats.memory.total - props.row.data.stats.memory.free) / (1024*1024*1024)) }}GB / {{ Math.round(props.row.data.stats.memory.total / (1024*1024*1024)) }}GB
                  <b-progress :value="props.row.data.stats.memory.total - props.row.data.stats.memory.free" :max="props.row.data.stats.memory.total" type="is-danger"></b-progress>
                </div>
            </b-table-column>
            
            <b-table-column field="health" label="health" width="100" v-slot="props">
              <div v-if="props.row.health !== null">
                <div :class="(props.row.data.systemStats.APTGET > 15) ? 'hasError' : ''">
                  <div class='itemtext'>apt-get:</div> 
                  {{ props.row.data.systemStats.APTGET }} open package(s)
                </div>
                
                <div :class="(props.row.health.laravel.error !== '' || props.row.health.laravel.duration > 1000) ? 'hasError' : ''">
                  <div class='itemtext clickable' @click='checkLaravel(props.row, true)'>laravel:</div> 
                  <a class='clickable' :href='`http://${props.row.data.info.ip}:9998/api/health/laravel`' target='_blank'>
                    {{ props.row.health.laravel.status === 'fetching' ? '-----' : `${props.row.health.laravel.duration}ms` }}
                  </a>
                </div>
                
                <div :class="(props.row.health.database.error !== '' || props.row.health.database.duration > 1000) ? 'hasError' : ''">
                  <div class='itemtext clickable' @click='checkDatabase(props.row, true)'>database:</div> 
                  <a class='clickable' :href='`http://${props.row.data.info.ip}:9998/api/health/database`' target='_blank'>
                    {{ props.row.health.database.status === 'fetching' ? '-----' : `${props.row.health.database.duration}ms` }}
                  </a>
                </div>
                
                <div :class="(props.row.health.redis.error !== '' || props.row.health.redis.duration > 1000) ? 'hasError' : ''">
                  <div class='itemtext clickable' @click='checkRedis(props.row, true)'>redis:</div>
                  <a class='clickable' :href='`http://${props.row.data.info.ip}:9998/api/health/redis`' target='_blank'>
                    {{ props.row.health.redis.status === 'fetching' ? '-----' : `${props.row.health.redis.duration}ms` }}
                  </a>
                </div>
                
                <div :class="(props.row.health.queue.error !== '' || props.row.health.queue.duration > 1000) ? 'hasError' : ''">
                  <div class='itemtext clickable' @click='checkQueue(props.row, true)'>queue:</div>
                  <a class='clickable' :href='`http://${props.row.data.info.ip}:9998/api/health/queue`' target='_blank'>
                    {{ props.row.health.queue.status === 'fetching' ? '-----' : `${props.row.health.queue.duration}ms - ${props.row.health.queue.response ? props.row.health.queue.response.failed_jobs : '?'} failed` }}
                  </a>
                </div>
                
                <div :class="(props.row.health.nuxt.error !== '' || props.row.health.nuxt.duration > 50000) ? 'hasError' : ''">
                  <div class='itemtext clickable' @click='checkNuxt(props.row, true)'>nuxt:</div>
                  <a class='clickable' :href='`http://${props.row.data.info.ip}:8085/`' target='_blank'>
                    {{ props.row.health.nuxt.status === 'fetching' ? '-----' : `${props.row.health.nuxt.duration}ms` }}
                  </a>
                </div>
                
                <div v-if="false && props.row.name === 'WaProduction|46-4-225-198'" :class="(props.row.health.pusher.error !== '' || props.row.health.pusher.duration > 60000) ? 'hasError' : ''">
                  <div class='itemtext clickable' @click="checkPusher(props.row, true)">pusher:</div>
                  <div>
                    {{ props.row.health.pusher.status === 'fetching' ? '-----' : `${props.row.health.pusher.duration}ms` }}
                  </div>
                  <br>
                  {{ props.row.health.pusher.response ? props.row.health.pusher.response : ' ' }}
                  {{ props.row.health.pusher.error ? props.row.health.pusher.error : ' ' }}
                </div>
              </div>
            </b-table-column>
            
            <b-table-column field="Lar-git" label="Lar-git" width="100" v-slot="props">
              <div :class="CalcGitStatusSameLaravel()">
                {{ GetArrayToString(props.row.data.systemStats.LARAVEL_GITBRANCH, '-') }} - 
                        {{ GetArrayToString(props.row.data.systemStats.LARAVEL_GITHASH, '-') }} -
                        {{ GetArrayToString(props.row.data.systemStats.LARAVEL_GITSTATUS, '-').indexOf('Your branch is up to date') > -1 ? 'OK' : 'NEEDS UPDATE!' }}
              </div>
              <div :class="CalcEnvFileHashSameLaravel()" @click="ShowEnvFiles(props.row.data.systemStats.LARAVEL_FILEHASHS)">
                Larvel env file hash{{ GetArrayToString(props.row.data.systemStats.LARAVEL_FILEHASHS.totalHashStringshort, '-') }}
              </div>
              <div>
                <b-button class="m5" @click="BTN_Laravelaction(props.row, 'laravel_gitpull')">git pull</b-button>
                <b-button class="m5" @click="BTN_Laravelaction(props.row, 'laravel_updatefast')">update fast</b-button>
                <b-button class="m5" @click="BTN_Laravelaction(props.row, 'laravel_status')">git status</b-button>
                <b-button v-if="props.row.LB_Enabled === false" class="m5" @click="BTN_Laravelaction(props.row, 'laravel_rebuilddocker')">rebuild docker</b-button>
                <b-button v-if="true || props.row.LB_Enabled === false" class="m5" @click="BTN_Laravelaction(props.row, 'laravel_dockerdown')">docker down</b-button>
                <b-button v-if="true || props.row.LB_Enabled === false" class="m5" @click="BTN_Laravelaction(props.row, 'laravel_dockerup')" >docker up</b-button>                
                <b-button v-if="props.row.LB_Enabled === false" class="m5" @click="BTN_Laravelaction(props.row, 'laravel_dockerstop')" >docker stop</b-button>
                <b-button v-if="true || props.row.LB_Enabled === false" class="m5" @click="BTN_Laravelaction(props.row, 'laravel_dockerstart')" >docker start</b-button>
                <b-button v-if="props.row.LB_Enabled === false" class="m5" @click="BTN_Laravelaction(props.row, 'laravel_dockerrestart')" >docker restart</b-button>
                <b-button class="m5" @click="BTN_Laravelaction(props.row, 'laravel_dockerps')">docker status</b-button>
                <b-button class="m5" @click="BTN_Laravelaction(props.row, 'laravel_appoptimize')">app:optimize</b-button>
              </div>
              
            </b-table-column>
            
            <b-table-column field="Nuxt-git" label="nuxt-git" width="100" v-slot="props">
              <div :class="CalcGitStatusSameNuxt()">
                {{ GetArrayToString(props.row.data.systemStats.NUXT_GITBRANCH, '-') }} - 
                        {{ GetArrayToString(props.row.data.systemStats.NUXT_GITHASH, '-') }} -
                        {{ GetArrayToString(props.row.data.systemStats.NUXT_GITSTATUS, '-').indexOf('Your branch is up to date') > -1 ? 'OK' : 'NEEDS UPDATE!' }}
              </div>
              <!-- <div :class="CalcEnvFileHashSameNuxt()" @click="ShowEnvFiles(props.row.data.systemStats.NUXT_FILEHASHS)">
                nuxt env file hash{{ GetArrayToString(props.row.data.systemStats.NUXT_FILEHASHS.totalHashStringshort, '-') }}
              </div> -->
              <div>
                <b-button class="m5" v-if="true || props.row.LB_Enabled === false" @click="BTN_NuxtAction(props.row, 'nuxt_git_pull')">git pull</b-button>
                <b-button class="m5" v-if="true || props.row.LB_Enabled === false" @click="BTN_NuxtAction(props.row, 'nuxt_rebuild')">docker rebuild</b-button>
                <b-button class="m5" v-if="true || props.row.LB_Enabled === false" @click="BTN_NuxtAction(props.row, 'nuxt_docker_down')">docker down</b-button>
                <b-button class="m5" v-if="true || props.row.LB_Enabled === false" @click="BTN_NuxtAction(props.row, 'nuxt_docker_up')">docker up</b-button>
                <!-- <b-button class="m5" @click="BTN_NuxtAction(props.row, 'nuxt_pm2start')">start</b-button>
                <b-button class="m5" @click="BTN_NuxtAction(props.row, 'nuxt_pm2reload')">reload</b-button> -->
              </div>
            </b-table-column>
            
            <template #detail="props">
              <div :key="`${props.row.name}_${LastUpdated}`">
                <div v-if="props.row.data.log">
                  <div v-for="(log, lindex) in props.row.data.loglist" v-bind:key="lindex">  
                    <div :class="log.code === 'stderrdata' ? 'hasError' : ''">
                      {{ log.data }}
                    </div>
                  </div>
                </div>
              </div>
            </template>

        </b-table>
      </div>
    </div>
    
    
    
    
    
    <div>
      <div class="columns is-marginless">
        <div class="column is-12">    
            <div class="columns is-gapless is-marginless is-centered is-mobile">
              <div class="column is-narrow is-mobile">
                <div class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined" >Database</div>
              </div>
            </div>
        </div>
      </div>
      
      <div class="is-mobile is-gapless is-centered is-multiline m2rem">
        <b-table
            :data="FilterServerData(['DATABASE'])"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            
            :show-detail-icon="true"
            detailed
            detail-key="name"
            
            :mobile-cards="true">

            <b-table-column field="name" label="Name" width="100" v-slot="props">
                {{ props.row.name }}
                <img class="wa-logo" src="/img/wa_logo.png" alt="WaarneemApp">
            </b-table-column>
            
            <b-table-column field="cpu" label="cpu" width="100" v-slot="props">
                <div>
                  cpu-s: {{ props.row.data.stats.cpu.load[0] }}
                  <b-progress :value="props.row.data.stats.cpu.load[0]" :max="props.row.data.stats.cpu.count/4" type="is-danger"></b-progress>
                </div>
                <div>
                  cpu-m: {{ props.row.data.stats.cpu.load[1] }}
                  <b-progress :value="props.row.data.stats.cpu.load[1]" :max="props.row.data.stats.cpu.count/4" type="is-danger"></b-progress>
                </div>
                <div>
                  cpu-l: {{ props.row.data.stats.cpu.load[2] }}
                  <b-progress :value="props.row.data.stats.cpu.load[2]" :max="props.row.data.stats.cpu.count/4" type="is-danger"></b-progress>
                </div>
                <div>
                  memory: {{ Math.round((props.row.data.stats.memory.total - props.row.data.stats.memory.free) / (1024*1024*1024)) }}GB / {{ Math.round(props.row.data.stats.memory.total / (1024*1024*1024)) }}GB
                  <b-progress :value="props.row.data.stats.memory.total - props.row.data.stats.memory.free" :max="props.row.data.stats.memory.total" type="is-danger"></b-progress>
                </div>
            </b-table-column>
            
            <b-table-column field="health" label="health" width="100" v-slot="props">
              <div>
                health checks..
              </div>
              <div>
                apt-get: {{ props.row.data.systemStats.APTGET }} open package(s)
              </div>
            </b-table-column>
            
            <b-table-column field="actions" label="actions" width="100" v-slot="props">
              <div>
                <b-button class="m5" @click="BTN_databaseaction(props.row, 'database_backup')">backup</b-button>
              </div>
            </b-table-column>
            
            <template #detail="props">
              <div :key="`${props.row.name}_${LastUpdated}`">
                <div v-if="props.row.data.log">
                  <div v-for="(log, lindex) in props.row.data.loglist" v-bind:key="lindex">  
                    <div :class="log.code === 'stderrdata' ? 'hasError' : ''">
                      {{ log.data }}
                    </div>
                  </div>
                </div>
              </div>
            </template>

        </b-table>
      </div>
      
    </div>
    
    
    <div class="is-mobile is-gapless is-centered is-multiline m2rem">
      <b-table
          :data="FilterServerData(['RELEASEWAARNEEMAPP'])"
          :bordered="true"
          :striped="true"
          :narrowed="true"
          
          :show-detail-icon="true"
          detailed
          detail-key="name"
          
          :mobile-cards="true">

          <b-table-column field="name" label="Name" width="100" v-slot="props">
              {{ props.row.name }}
              <img class="wa-logo" src="/img/wa_logo.png" alt="WaarneemApp">
              <a class='clickable' :href='`https://release.waarneemapp.nl`' target='_blank'>
                https://release.waarneemapp.nl
              </a>
          </b-table-column>
          
          <b-table-column field="cpu" label="cpu" width="100" v-slot="props">
              <div>
                cpu-s: {{ props.row.data.stats.cpu.load[0] }}
                <b-progress :value="props.row.data.stats.cpu.load[0]" :max="props.row.data.stats.cpu.count/4" type="is-danger"></b-progress>
              </div>
              <div>
                cpu-m: {{ props.row.data.stats.cpu.load[1] }}
                <b-progress :value="props.row.data.stats.cpu.load[1]" :max="props.row.data.stats.cpu.count/4" type="is-danger"></b-progress>
              </div>
              <div>
                cpu-l: {{ props.row.data.stats.cpu.load[2] }}
                <b-progress :value="props.row.data.stats.cpu.load[2]" :max="props.row.data.stats.cpu.count/4" type="is-danger"></b-progress>
              </div>
              <div>
                memory: {{ Math.round((props.row.data.stats.memory.total - props.row.data.stats.memory.free) / (1024*1024*1024)) }}GB / {{ Math.round(props.row.data.stats.memory.total / (1024*1024*1024)) }}GB
                <b-progress :value="props.row.data.stats.memory.total - props.row.data.stats.memory.free" :max="props.row.data.stats.memory.total" type="is-danger"></b-progress>
              </div>
          </b-table-column>
          
          <b-table-column field="health" label="health" width="100" v-slot="props">
            <div>
              health checks..
            </div>
            <div>
              apt-get: {{ props.row.data.systemStats.APTGET }} open package(s)
            </div>
            
            <div>
              <div class='itemtext'>laravel:</div>
              <a class='clickable' :href='`http://release.waarneemapp.nl:9998/api/health/laravel`' target='_blank'>
                check
              </a>
            </div>
            
            <div>
              <div class='itemtext'>database:</div> 
              <a class='clickable' :href='`http://release.waarneemapp.nl:9998/api/health/database`' target='_blank'>
                 check
              </a>
            </div>
            
            <div>
              <div class='itemtext'>redis:</div>
              <a class='clickable' :href='`http://release.waarneemapp.nl:9998/api/health/redis`' target='_blank'>
                check
              </a>
            </div>
            
            <div>
              <div class='itemtext'>queue:</div>
              <a class='clickable' :href='`http://release.waarneemapp.nl:9998/api/health/queue`' target='_blank'>
                check
              </a>
            </div>
            
            <div>
              <div class='itemtext'>nuxt:</div>
              <a class='clickable' :href='`http://release.waarneemapp.nl`' target='_blank'>
                check
              </a>
            </div>
                
          </b-table-column>
          
          
          <b-table-column field="Lar-git" label="Lar-git" width="100" v-slot="props">
            <div>
              <b-button class="m5" @click="BTN_Laravelaction(props.row, 'release_laravel_gitpull')">git pull</b-button>
              <b-button class="m5" @click="BTN_Laravelaction(props.row, 'release_laravel_updatefast')">update fast</b-button>
              <b-button class="m5" @click="BTN_Laravelaction(props.row, 'release_laravel_status')">git status</b-button>
              <b-button class="m5" @click="BTN_Laravelaction(props.row, 'release_laravel_rebuilddocker')">rebuild docker</b-button>
              <b-button class="m5" @click="BTN_Laravelaction(props.row, 'release_laravel_dockerdown')">docker down</b-button>
              <b-button class="m5" @click="BTN_Laravelaction(props.row, 'release_laravel_dockerup')" >docker up</b-button>                
              <b-button class="m5" @click="BTN_Laravelaction(props.row, 'release_laravel_dockerstop')" >docker stop</b-button>
              <b-button class="m5" @click="BTN_Laravelaction(props.row, 'release_laravel_dockerstart')" >docker start</b-button>
              <b-button class="m5" @click="BTN_Laravelaction(props.row, 'release_laravel_dockerrestart')" >docker restart</b-button>
              <b-button class="m5" @click="BTN_Laravelaction(props.row, 'release_laravel_dockerps')">docker status</b-button>
              <b-button class="m5" @click="BTN_Laravelaction(props.row, 'release_laravel_appoptimize')">app:optimize</b-button>
            </div>
            
          </b-table-column>
          
          <b-table-column field="Nuxt-git" label="nuxt-git" width="100" v-slot="props">
            <!-- <div :class="CalcGitStatusSameNuxt()">
              {{ GetArrayToString(props.row.data.systemStats.NUXT_GITBRANCH, '-') }} - 
                      {{ GetArrayToString(props.row.data.systemStats.NUXT_GITHASH, '-') }} -
                      {{ GetArrayToString(props.row.data.systemStats.NUXT_GITSTATUS, '-').indexOf('Your branch is up to date') > -1 ? 'OK' : 'NEEDS UPDATE!' }}
            </div> -->
            <!-- <div :class="CalcEnvFileHashSameNuxt()" @click="ShowEnvFiles(props.row.data.systemStats.NUXT_FILEHASHS)">
              nuxt env file hash{{ GetArrayToString(props.row.data.systemStats.NUXT_FILEHASHS.totalHashStringshort, '-') }}
            </div> -->
            <div>
              <b-button class="m5" @click="BTN_NuxtAction(props.row, 'release_nuxt_update')">update nuxt</b-button>
              <b-button class="m5" @click="BTN_NuxtAction(props.row, 'release_nuxt_pm2stop')">stop</b-button>
              <b-button class="m5" @click="BTN_NuxtAction(props.row, 'release_nuxt_pm2start')">start</b-button>
              <b-button class="m5" @click="BTN_NuxtAction(props.row, 'release_nuxt_pm2reload')">reload</b-button>
            </div>
          </b-table-column>
          
          <b-table-column field="actions" label="actions" width="100" v-slot="props">
            <div>
              <b-button class="m5" @click="BTN_demoaction(props.row, 'release_laravel_clone')">clone</b-button>
              <b-button class="m5" @click="BTN_demoaction(props.row, 'release_setBranchDev')">development branch</b-button>
              <b-button class="m5" @click="BTN_demoaction(props.row, 'release_setBranchRelease')">release branch</b-button>
              <b-button class="m5" @click="BTN_demoaction(props.row, 'release_setBranchMaster')">master branch</b-button>
              <!-- <b-button class="m5" @click="BTN_demoaction(props.row, 'demo_whitelist_add_ip')">whitelist add IP</b-button> -->
              <!-- <b-button class="m5" @click="BTN_demoaction(props.row, 'demo_whitelist_remove_ip')">whitelist remove IP</b-button> -->
            </div>
          </b-table-column>
          
          <template #detail="props">
            <div :key="`${props.row.name}_${LastUpdated}`">
              <div v-if="props.row.data.log">
                <div v-for="(log, lindex) in props.row.data.loglist" v-bind:key="lindex">  
                  <div :class="log.code === 'stderrdata' ? 'hasError' : ''">
                    {{ log.data }}
                  </div>
                </div>
              </div>
            </div>
          </template>

      </b-table>
    </div>
    
    
    <div class="is-mobile is-gapless is-centered is-multiline m2rem">
      <b-table
          :data="FilterServerData(['DEMOWAARNEEMAPP'])"
          :bordered="true"
          :striped="true"
          :narrowed="true"
          
          :show-detail-icon="true"
          detailed
          detail-key="name"
          
          :mobile-cards="true">

          <b-table-column field="name" label="Name" width="100" v-slot="props">
              {{ props.row.name }}
              <img class="wa-logo" src="/img/wa_logo.png" alt="WaarneemApp">
              <a class='clickable' :href='`https://demo.waarneemapp.nl`' target='_blank'>
                https://demo.waarneemapp.nl
              </a>
          </b-table-column>
          
          <b-table-column field="cpu" label="cpu" width="100" v-slot="props">
              <div>
                cpu-s: {{ props.row.data.stats.cpu.load[0] }}
                <b-progress :value="props.row.data.stats.cpu.load[0]" :max="props.row.data.stats.cpu.count/4" type="is-danger"></b-progress>
              </div>
              <div>
                cpu-m: {{ props.row.data.stats.cpu.load[1] }}
                <b-progress :value="props.row.data.stats.cpu.load[1]" :max="props.row.data.stats.cpu.count/4" type="is-danger"></b-progress>
              </div>
              <div>
                cpu-l: {{ props.row.data.stats.cpu.load[2] }}
                <b-progress :value="props.row.data.stats.cpu.load[2]" :max="props.row.data.stats.cpu.count/4" type="is-danger"></b-progress>
              </div>
              <div>
                memory: {{ Math.round((props.row.data.stats.memory.total - props.row.data.stats.memory.free) / (1024*1024*1024)) }}GB / {{ Math.round(props.row.data.stats.memory.total / (1024*1024*1024)) }}GB
                <b-progress :value="props.row.data.stats.memory.total - props.row.data.stats.memory.free" :max="props.row.data.stats.memory.total" type="is-danger"></b-progress>
              </div>
          </b-table-column>
          
          <b-table-column field="health" label="health" width="100" v-slot="props">
            <div>
              health checks..
            </div>
            <div>
              apt-get: {{ props.row.data.systemStats.APTGET }} open package(s)
            </div>
            
            <div>
              <div class='itemtext'>laravel:</div>
              <a class='clickable' :href='`http://demo.waarneemapp.nl:9998/api/health/laravel`' target='_blank'>
                check
              </a>
            </div>
            
            <div>
              <div class='itemtext'>database:</div> 
              <a class='clickable' :href='`http://demo.waarneemapp.nl:9998/api/health/database`' target='_blank'>
                 check
              </a>
            </div>
            
            <div>
              <div class='itemtext'>redis:</div>
              <a class='clickable' :href='`http://demo.waarneemapp.nl:9998/api/health/redis`' target='_blank'>
                check
              </a>
            </div>
            
            <div>
              <div class='itemtext'>queue:</div>
              <a class='clickable' :href='`http://demo.waarneemapp.nl:9998/api/health/queue`' target='_blank'>
                check
              </a>
            </div>
            
            <div>
              <div class='itemtext'>nuxt:</div>
              <a class='clickable' :href='`http://demo.waarneemapp.nl`' target='_blank'>
                check
              </a>
            </div>
          </b-table-column>
          
          
          <b-table-column field="Lar-git" label="Lar-git" width="100" v-slot="props">
            <div>
              <b-button class="m5" @click="BTN_Laravelaction(props.row, 'demo_laravel_gitpull')">git pull</b-button>
              <b-button class="m5" @click="BTN_Laravelaction(props.row, 'demo_laravel_updatefast')">update fast</b-button>
              <b-button class="m5" @click="BTN_Laravelaction(props.row, 'demo_laravel_status')">git status</b-button>
              <b-button class="m5" @click="BTN_Laravelaction(props.row, 'demo_laravel_rebuilddocker')">rebuild docker</b-button>
              <b-button class="m5" @click="BTN_Laravelaction(props.row, 'demo_laravel_dockerdown')">docker down</b-button>
              <b-button class="m5" @click="BTN_Laravelaction(props.row, 'demo_laravel_dockerup')" >docker up</b-button>
              <b-button class="m5" @click="BTN_Laravelaction(props.row, 'demo_laravel_dockerstop')" >docker stop</b-button>
              <b-button class="m5" @click="BTN_Laravelaction(props.row, 'demo_laravel_dockerstart')" >docker start</b-button>
              <b-button class="m5" @click="BTN_Laravelaction(props.row, 'demo_laravel_dockerrestart')" >docker restart</b-button>
              <b-button class="m5" @click="BTN_Laravelaction(props.row, 'demo_laravel_dockerps')">docker status</b-button>
              <b-button class="m5" @click="BTN_Laravelaction(props.row, 'demo_laravel_appoptimize')">app:optimize</b-button>
            </div>
            
          </b-table-column>
          
          <b-table-column field="Nuxt-git" label="nuxt-git" width="100" v-slot="props">
            <!-- <div :class="CalcGitStatusSameNuxt()">
              {{ GetArrayToString(props.row.data.systemStats.NUXT_GITBRANCH, '-') }} - 
                      {{ GetArrayToString(props.row.data.systemStats.NUXT_GITHASH, '-') }} -
                      {{ GetArrayToString(props.row.data.systemStats.NUXT_GITSTATUS, '-').indexOf('Your branch is up to date') > -1 ? 'OK' : 'NEEDS UPDATE!' }}
            </div> -->
            <!-- <div :class="CalcEnvFileHashSameNuxt()" @click="ShowEnvFiles(props.row.data.systemStats.NUXT_FILEHASHS)">
              nuxt env file hash{{ GetArrayToString(props.row.data.systemStats.NUXT_FILEHASHS.totalHashStringshort, '-') }}
            </div> -->
            <div>
              <b-button class="m5" @click="BTN_NuxtAction(props.row, 'demo_nuxt_update')">update nuxt</b-button>
              <b-button class="m5" @click="BTN_NuxtAction(props.row, 'demo_nuxt_pm2stop')">stop</b-button>
              <b-button class="m5" @click="BTN_NuxtAction(props.row, 'demo_nuxt_pm2start')">start</b-button>
              <b-button class="m5" @click="BTN_NuxtAction(props.row, 'demo_nuxt_pm2reload')">reload</b-button>
            </div>
          </b-table-column>
          
          <b-table-column field="actions" label="actions" width="100" v-slot="props">
            <div>
              <b-button class="m5" @click="BTN_demoaction(props.row, 'demo_laravel_clone')">clone</b-button>
              <b-button class="m5" @click="BTN_demoaction(props.row, 'demo_setBranchDev')">development branch</b-button>
              <b-button class="m5" @click="BTN_demoaction(props.row, 'demo_setBranchRelease')">release branch</b-button>
              <b-button class="m5" @click="BTN_demoaction(props.row, 'demo_setBranchMaster')">master branch</b-button>
              <!-- <b-button class="m5" @click="BTN_demoaction(props.row, 'demo_whitelist_add_ip')">whitelist add IP</b-button> -->
              <!-- <b-button class="m5" @click="BTN_demoaction(props.row, 'demo_whitelist_remove_ip')">whitelist remove IP</b-button> -->
            </div>
          </b-table-column>
          
          <template #detail="props">
            <div :key="`${props.row.name}_${LastUpdated}`">
              <div v-if="props.row.data.log">
                <div v-for="(log, lindex) in props.row.data.loglist" v-bind:key="lindex">  
                  <div :class="log.code === 'stderrdata' ? 'hasError' : ''">
                    {{ log.data }}
                  </div>
                </div>
              </div>
            </div>
          </template>

      </b-table>
    </div>
    
    <b-modal v-model="showHTMLeditor" full-screen :can-cancel="true">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">HTML edit</p>
        </header>
        <section class="modal-card-body">
            <b-field label="html">
                <b-input
                    type="textarea"
                    v-model="formProps.html"
                    maxlength="200000"
                    placeholder="html"
                    rows="1000" cols="30"
                    required>
                </b-input>
            </b-field>

        </section>
        <footer class="modal-card-foot">
            <b-button
                label="Save"
                @click="HTML_editor_save()"
                type="is-primary" />
        </footer>
      </div>
    </b-modal>
    
    <b-modal v-model="showJSONeditor" full-screen :can-cancel="true">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">JSON edit</p>
        </header>
        <section class="modal-card-body">
            <b-field label="html">
                <b-input
                    type="textarea"
                    v-model="jsonProps.json"
                    maxlength="200000"
                    placeholder="json"
                    rows="1000" cols="30"
                    required>
                </b-input>
            </b-field>

        </section>
        <footer class="modal-card-foot">
            <b-button
                label="Save"
                @click="JSON_editor_save()"
                type="is-primary" />
        </footer>
      </div>
    </b-modal>

    
    
    <b-notification :closable="false">
      <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
    </b-notification>
  </section>
</template>

<script>

import { FBsignInAnonymously }             from '@/plugins/firebase/auth';
import { FBgetData, FBmonitorData }               from '@/plugins/firebase/db';
import axios_clean          from 'axios';
import { waitTime }         from '@/helpers/functions';
// import { mapState } from 'vuex';

export default {
  name: "SuperAdminPage", 
         
  data() {
    return {
      projectTitle  : process.env.VUE_APP_TITLE,
      SITE_ROOT     : process.env.VUE_APP_SITE_MAIN,
      // isLoggedIn    : null,
      
      serverlist : [],
      tabInForeground : true,
      pageLoading : false,
      pageLoadingTimeout : null,
      
      LastUpdated : null,
      
      showJSONeditor : false,
      jsonProps : {
                    server: null,
                    json: `{
                              "vimeourl":"https://player.vimeo.com/video/547981780?autoplay=1&loop=0&autopause=0#t=27m27s",
                              "chaturl":"https://vimeo.com/live-chat/521496969/0ef149251a",
                              "presentatie_url": "/files/Hot_n_hapeningH_3-11-2020_webinarpijn_grunenthal_presentatie_def.pdf",
                              "googe_docs_url" : "https://docs.google.com/forms/d/e/1FAIpQLSerYjtkRaQhjWT5jcK8nYUhWNpDiEDF43sXw2qw680vPBmQfw/viewform?embedded=true"
                            }`
                },
      
      showHTMLeditor : false,
      formProps: {
                    server: null,
                    html: '<h2>Momenteel wordt de WaarneemApp website ge&uuml;pdate. Over een paar minuten is de website weer toegankelijk, probeert u het dan opnieuw. Excuus voor het ongemak.</h2>'
                },
      
      MAINHOST : process.env.VUE_APP_MAINHOST,
      mainhealth : {
                        laravel: {
                          duration: 0,
                          response: '',
                          error: '',
                          status: 'loading',
                        },
                        database: {
                          duration: 0,
                          response: '',
                          error: '',
                          status: 'loading',
                        },
                        redis: {
                          duration: 0,
                          response: '',
                          error: '',
                          status: 'loading',
                        },
                        queue: {
                          duration: 0,
                          response: '',
                          error: '',
                          status: 'loading',
                        },
                        pusher: {
                          duration: 0,
                          response: '',
                          pusherresp: '',
                          error: '',
                          status: 'loading',
                        },
                        nuxt: {
                          duration: 0,
                          response: '',
                          pusherresp: '',
                          error: '',
                          status: 'loading',
                        },
                        testemail: '',
                        testsms: '',
                        testbignr: '19918268701',
                        bigdataresponse: '',
                      },
      
      enablemonitoring : false,
      autoDisableMonitorTimer: null,
    }
  },
  
  // computed: {
  //   ...mapState('authentication', ['authentication/loggedInState','loggedInState']),
  // },
  // watch: {
  //   loggedInState(loggedInState) {
  //     if (this.isLoggedIn === null) {
  //       this.isLoggedIn = loggedInState;
  //       this.firebaseInit();
  //     }
  //     console.info("LoggedInState Changed:",  loggedInState);
  //   },
  // },
  
  mounted(){
    this.$store.dispatch('authentication/updateLoginState', null);
    
    this.firebaseInit();
    
    document.addEventListener('visibilitychange', () =>{
      // console.info('visibilitychange', event);
      if(document.visibilityState == 'hidden') {
        // console.info('hidden');
        this.tabInForeground = false;
      } else {
        // console.info('visible');
        this.tabInForeground = true;
      }
    })
  },
  
  methods: {
    async firebaseInit(){
      try {
        const FBData = await FBsignInAnonymously();
        await this.$store.dispatch('monitor/FBAuthAddUsers', FBData.user.uid);   
        // await waitTime(500);
        
        //read servers
        await this.FBReadServers();
        
        //Ping healthcheck urls
        this.$store.dispatch('monitor/FBRequestUpdates', this.GetFBServerList());
        const _this = this;
        setInterval(() => {
          console.info(`_this.enablemonitoring:`, _this.enablemonitoring)
          if (_this.tabInForeground && _this.enablemonitoring === true) {
            _this.$store.dispatch('monitor/FBRequestUpdates', _this.GetFBServerList());          
          } else {
            console.info(`skipping update, not in forground/enablemonitoring`);
          }
        }, 60*1000)
        
        
        //Firebase Monitor servers - cpu/memory/etc
        this.FBMonitorServers();
        
        //Firebase Monitor servers SystemStats
        this.FBMonitorServersSystemStats();
        
        //Firebase Monitor servers SystemStats
        this.FBMonitorServersSystemLogs();
        
        //Firebase Monitor Load balancer
        this.FBMonitorReadBalancer();
        this.FBMonitorLoadBalancer();
        
        //Ping healthcheck urls
        this.HealthCheckUrls()
        
        //Auto disable monitoring after x seconds!
        this.autoDisableMonitoring();
        
      } catch (err) { 
        console.info('Error firebaseInit:', err.message); 
      }
    },
    
    async FBReadServers() {
      try {
        const mainpath = `/wamonitor/servers`;
        const servers = await FBgetData(mainpath);
        console.info(`servers:`, servers);
        const serverlist = servers;
        
        let list = [];
        for(let key in serverlist) {
          list.push({
            name: key,
            path: `${mainpath}/${key}`,
            data: serverlist[key],
            LB_EnabledToggle: null,
            LB_Enabled: null,
            health: null,
          })
        }
        
        this.serverlist = list;
        console.info(`this.serverlist:`, this.serverlist);
        console.info(`this.serverlist names`, this.serverlist.map((server) => { return server.name }));
        
      } catch (err) { 
        console.info('Error FBReadServers:', err.message); 
      }
    },
    
    setLastUpdated(){
      this.LastUpdated = new Date().getTime();
    },
    
    async FBMonitorServers(){
      for(let server of this.serverlist) {        
        // console.info(`monitoring: ${server.path}/stats`);      
        FBmonitorData(`${server.path}/stats`, (data) => {  
          if (!data) {
            return;
          }
      
          try {
            const snapshot = data;
            // console.info('snapshot ->' + server.name, snapshot);
            server.data.stats = snapshot;
            this.setLastUpdated();
          
          } catch (err) {
            console.info('FB Error:', err.message);
          }
        })
      }
    },
    
    
    async FBMonitorServersSystemStats(){
      for(let server of this.serverlist) {
        // console.info(`monitoring: ${server.path}/stats`);  
        FBmonitorData(`${server.path}/systemStats`, (data) =>{
          if (!data) {
            return;
          }
          
          try {
            const snapshot = data;
            // console.info('snapshot ->' + server.name, snapshot);
            server.data.systemStats = snapshot;
            this.setLastUpdated();
          } catch (err) {
            console.info('FB Error:', err.message);
          }
        })
      }
    },
    
    
    async FBMonitorReadBalancer(){
      // const loadbalancerpath = `/wamonitor/loadbalancer_config`;
      // const LBservers = await DB.ref(loadbalancerpath).once('value');
      // console.info(`LBservers:`, LBservers.val());
      // this.ProcessLoadBalancerSettings(LBservers.val());
    },
    
    async FBMonitorLoadBalancer(){
      const loadbalancerpath = `/wamonitor/loadbalancer_config`;
      const _this = this;
      FBmonitorData(loadbalancerpath, (data) => {
        if (!data) {
          return;
        }
      
        try {
          const snapshot = data;
          // console.info('ProcessLoadBalancerSettings ->', snapshot);
          _this.ProcessLoadBalancerSettings(snapshot);
        } catch (err) {
          console.info('FB Error:', err.message);
        }   
      })
    },
    
    async ProcessLoadBalancerSettings(snapshot){
      console.info(`ProcessLoadBalancerSettings:::`, snapshot);
      for(let server of this.serverlist) {
        console.info(`server:::`, server.data.info.type);
        if (server.data.info.type !== 'LARAVELNUXT') {
          continue;
        }
        let LBFindServerSatus = null;
        for(let LBServer of snapshot.servers) {
          // console.info(`LBServer.name:`, LBServer.name);
          // console.info(`server.name:`, server.name);
          if (LBServer.name === server.name) {
            LBFindServerSatus = LBServer.enabled;
            break;
          }
        }
        server.LB_EnabledToggle = LBFindServerSatus;
        server.LB_Enabled       = LBFindServerSatus;
      }
      
      this.setLastUpdated();
    },
    
    
    async FBMonitorServersSystemLogs(){
      for(let server of this.serverlist) {
        server.data.log     = {};
        server.data.loglist = [];
        FBmonitorData(`${server.path}/log`, (data) => {
          if (!data) {
            return;
          }
          
          try {
            const snapshot = data;
            // console.info('snapshot ->>>>>' + server.name, snapshot);
            for(let key in snapshot) {
              for(let logitem in snapshot[key].logData) {
                server.data.log[key + '_' + logitem] = snapshot[key].logData[logitem];
              }
            }
            // console.info(`server.data.log:`, server.data.log);
        
            let logsorted = [];
            for(let key in server.data.log) {
              if (typeof(server.data.log[key].data) === "string") {
                let lines = server.data.log[key].data.split('\n');
                // console.info(`lines:`, lines);
                for(let line of lines) {
                  logsorted.push({
                    code: server.data.log[key].code,
                    data: line,
                  });  
                }
              }
              // logsorted.push(server.data.log[key]);  
            }
            // console.info(`logsorted:`, logsorted);
          
            //sort based on time
            logsorted.sort((a,b) => {
              return a.t - b.t
            });
            server.data.loglist = logsorted;
            // console.info(`logsorted: ${server.name}:`, logsorted);
            this.setLastUpdated();
          
          } catch (err) {
            console.info('FB Error:', err.message);
          }        
        })
      }
    },
    
    GetFBServerList(){
      let rtn = [];
      for(let server of this.serverlist) {
        rtn.push(server.name);
      }
      return rtn.join("###");
    },
    
    getString(item, Failvalue = '-'){
      if (typeof(item) === 'undefined') {
        return Failvalue;
      }
      
      return item;
    },
    
    GetArrayToString(item, Failvalue = '-', joinValue = ' ', trimStr = true){
      if (typeof(item) === 'undefined') {
        return Failvalue;
      }
      if (typeof(item) === 'string') {
        return item;
      }
      
      let rtn = item.join(joinValue);
      if (trimStr) {
        rtn = rtn.trim();
      }
      
      return rtn
    },
    
    
    // BTN_systemupdate(server){
    //   console.info(`BTN_systemupdate`, server);
    //   server.data.log = {};
    //   server.data.loglist = [];
    //   this.$store.dispatch('monitor/FBRequestAction', { server: server.name, action: 'systemupdate', payload: null });  
    // },
    
    
    BTN_Laravelaction(server, action = 'laravel_rebuilddocker', payload = null){
      console.info(`BTN_Laravelaction ${action}`, server);
      server.data.log = {};
      server.data.loglist = [];
      this.$store.dispatch('monitor/FBRequestAction', { server: server.name, action, payload });  
    },
    
    
    BTN_NuxtAction(server, action, payload = null){
      // console.info(`BTN_NuxtAction`, server);
      server.data.log = {};
      server.data.loglist = [];
      this.$store.dispatch('monitor/FBRequestAction', { server: server.name, action, payload });  
    },
    
    
    BTN_databaseaction(server, action, payload = null){
      console.info(`BTN_databaseaction`, server);
      server.data.log = {};
      server.data.loglist = [];
      this.$store.dispatch('monitor/FBRequestAction', { server: server.name, action, payload });  
    },
    
    BTN_demoaction(server, action, payload = null){
      console.info(`BTN_demoaction`, server);
      server.data.log = {};
      server.data.loglist = [];
      this.$store.dispatch('monitor/FBRequestAction', { server: server.name, action, payload });  
    },
    
    
    BTN_loadbalancerAction(server, action, payload = null){
      console.info(`BTN_loadbalancerAction`, server);
      const _this = this;
      this.$buefy.dialog.confirm({
          message: 'Are you sure you want to change the loadbalancer config???',
          onConfirm: () => {
            server.data.log = {};
            server.data.loglist = [];
            _this.SendToAllLoadBalancerAction(action, payload);
            // _this.$store.dispatch('monitor/FBRequestAction', { server: server.name, action, payload }); 
          }
      });
      
       
    },
    
    BTN_loadbalancerSetFallbackSite(server) {
      // console.info(`server:`, server);
      this.formProps.server = server;
      this.showHTMLeditor = true;
    },
    HTML_editor_save(){
      console.info(`html:`, this.formProps.html);      
      const action = 'loadbalancer_save_html';
      const payload = this.formProps.html;      
      this.formProps.server.data.log = {};
      this.formProps.server.data.loglist = [];
      
      this.SendToAllLoadBalancerAction(action, payload);
      // this.$store.dispatch('monitor/FBRequestAction', { server: this.formProps.server.name, action, payload });  
      
      this.showHTMLeditor = false;
    },
    
    
    BTN_loadbalancerSetFallbackJSON(server) {
      // console.info(`server:`, server);
      this.jsonProps.server = server;
      this.showJSONeditor = true;
    },    
    JSON_editor_save(){
      console.info(`json:`, this.jsonProps);   
      try {
        let validate_json = JSON.parse(this.jsonProps.json);
        console.info(`validate_json:`, validate_json);
      } catch (err) {
        return this.$buefy.dialog.alert('json error...')
      }  
      // return this.$buefy.dialog.alert('Everything looks fine!');
      
      const action = 'loadbalancer_save_json';
      const payload = this.jsonProps.json;      
      this.jsonProps.server.data.log = {};
      this.jsonProps.server.data.loglist = [];
      this.SendToAllLoadBalancerAction(action, payload);
      // this.$store.dispatch('monitor/FBRequestAction', { server: this.jsonProps.server.name, action, payload });  
      
      this.showJSONeditor = false;
    },
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    HealthCheckUrls(){     
      
      for(let server of this.serverlist) {
        // console.info(`server:::`, server.data.info.type);
        if (server.data.info.type === 'LARAVELNUXT') {
          this.InitLaravelHealthCheck(server);
        } else if (server.data.info.type === 'DATABASE') {
          // console.info(`server.data.info.type DATABASE`);
        } else if (server.data.info.type === 'LOADBALANCER') {
          // console.info(`server.data.info.type LOADBALANCER`);
        } else if (server.data.info.type === 'DEMOWAARNEEMAPP') {
          // console.info(`server.data.info.type DEMOWAARNEEMAPP`);
        } else if (server.data.info.type === 'RELEASEWAARNEEMAPP') {
          // console.info(`server.data.info.type RELEASEWAARNEEMAPP`);
        } else {
          console.info(`unknown server type:`, server.data.info.type);
        }
      }
    },
    
    InitLaravelHealthCheck(server) {
      server.health = {
                        laravel: {
                          duration: 0,
                          response: '',
                          error: '',
                          status: 'loading',
                        },
                        database: {
                          duration: 0,
                          response: '',
                          error: '',
                          status: 'loading',
                        },
                        redis: {
                          duration: 0,
                          response: '',
                          error: '',
                          status: 'loading',
                        },
                        queue: {
                          duration: 0,
                          response: '',
                          error: '',
                          status: 'loading',
                        },
                        pusher: {
                          duration: 0,
                          response: '',
                          pusherresp: '',
                          error: '',
                          status: 'loading',
                        },
                        nuxt: {
                          duration: 0,
                          response: '',
                          pusherresp: '',
                          error: '',
                          status: 'loading',
                        },
                        testemail: '',
                        testsms: '',
                        testbignr: '19918268701',
                        bigdataresponse: '',
                      };
                      
      const intervaltime = 60;
      
      this.checkLaravel(server);
      setInterval(() => {
        this.checkLaravel(server);
      }, intervaltime * 1000 + Math.floor(Math.random() * 1000));
      
      this.checkDatabase(server);
      setInterval(() => {
        this.checkDatabase(server);
      }, intervaltime * 1000 + Math.floor(Math.random() * 1000));
      
      this.checkRedis(server);
      setInterval(() => {
        this.checkRedis(server);
      }, intervaltime * 1000 + Math.floor(Math.random() * 1000));
      
      this.checkQueue(server);
      setInterval(() => {
        this.checkQueue(server);
      }, intervaltime * 1000 + Math.floor(Math.random() * 1000));
      
      this.checkNuxt(server);
      setInterval(() => {
        this.checkNuxt(server);
      }, intervaltime * 1000 + Math.floor(Math.random() * 1000));
      
      
      // if (server.name === 'WaProduction|46-4-225-198') {
      //   this.checkPusher(server);
      //   setInterval(() => {
      //     this.checkPusher(server);
      //   }, 2*intervaltime * 1000 + Math.floor(Math.random() * 1000));
      // }
      
      
      
      
      // Main Domain
      this.checkMainLaravel();
      setInterval(() => {
        this.checkMainLaravel();
      }, intervaltime * 1000 + Math.floor(Math.random() * 1000));
      
      this.checkMainDatabase();
      setInterval(() => {
        this.checkMainDatabase();
      }, intervaltime * 1000 + Math.floor(Math.random() * 1000));
      
      this.checkMainRedis();
      setInterval(() => {
        this.checkMainRedis();
      }, intervaltime * 1000 + Math.floor(Math.random() * 1000));
      
      this.checkMainQueue();
      setInterval(() => {
        this.checkMainQueue();
      }, intervaltime * 1000 + Math.floor(Math.random() * 1000));
      
      this.checkMainNuxt();
      setInterval(() => {
        this.checkMainNuxt();
      }, intervaltime * 1000 + Math.floor(Math.random() * 1000));
      
    },
    
    CalcGitStatusSameNuxt(){
      let Branchinfo = null;
      
      for(let server of this.serverlist) {
        if (server.data.info.type !== 'LARAVELNUXT') continue;
        let GitStatusNuxt = server.data.systemStats.NUXT_GITBRANCH + "|" + server.data.systemStats.NUXT_GITHASH;
        if (Branchinfo === null) {
          Branchinfo = GitStatusNuxt;  
        }
        
        if (Branchinfo !== GitStatusNuxt) {
          return 'GitStatusFailed';
        }        
      }
      
      return 'GitStatusOK';
    },
    
    
    CalcEnvFileHashSameLaravel(){
      let FileHash = null;
      
      for(let server of this.serverlist) {
        if (server.data.info.type !== 'LARAVELNUXT') continue;
        let hash = server.data.systemStats.LARAVEL_FILEHASHS.totalHashStringshort;
        if (FileHash === null) {
          FileHash = hash;  
        }
        
        if (FileHash !== hash) {
          return 'HashStatusFailed';
        }        
      }
      
      return 'HashStatusOK';
    },
    
    
    CalcEnvFileHashSameNuxt(){
      let FileHash = null;
      
      for(let server of this.serverlist) {
        if (server.data.info.type !== 'LARAVELNUXT') continue;
        let hash = server.data.systemStats.NUXT_FILEHASHS.totalHashStringshort;
        if (FileHash === null) {
          FileHash = hash;  
        }
        
        if (FileHash !== hash) {
          return 'HashStatusFailed';
        }        
      }
      
      return 'HashStatusOK';
    },
    
    
    
    
    
    CalcGitStatusSameLaravel(){
      let Branchinfo = null;
      
      for(let server of this.serverlist) {
        if (server.data.info.type !== 'LARAVELNUXT') continue;
        let GitStatusNuxt = server.data.systemStats.LARAVEL_GITBRANCH + "|" + server.data.systemStats.LARAVEL_GITHASH;
        if (Branchinfo === null) {
          Branchinfo = GitStatusNuxt;  
        }
        
        if (Branchinfo !== GitStatusNuxt) {
          return 'GitStatusFailed';
        }        
      }
      
      return 'GitStatusOK';
    },
    
    async CalcResponseColorCode(responseTime) {
      return responseTime;
    },
    
    async autoDisableMonitoring(){
      console.info(`autoDisableMonitoring...`)
      if (this.autoDisableMonitorTimer !== null) {
        clearTimeout(this.autoDisableMonitorTimer)
      }
      const _this = this;
      this.autoDisableMonitorTimer = setTimeout(() => {
        console.info(`autoDisableMonitoring finished...`)
        _this.enablemonitoring = false;
        _this.changeautomonitor();
      }, 5*60*1000)
    },
    
    async changeautomonitor() {
      this.autoDisableMonitoring();
      
      // console.info(`this.enablemonitoring:`, this.enablemonitoring);
      if (!this.enablemonitoring) return;
      console.info(`changeautomonitor:`, this.enablemonitoring);
      
      for(let server of this.serverlist) {
        // console.info(`server:::`, server.data.info.type);
        
        if (server.data.info.type === 'LARAVELNUXT') {
          this.checkLaravel(server);
          this.checkDatabase(server);
          this.checkRedis(server);
          this.checkQueue(server);
          this.checkNuxt(server);
          // this.checkPusher(server);
          await waitTime(1000);
          
        } else if (server.data.info.type === 'DATABASE') {
          // console.info(`server.data.info.type DATABASE`);
          
        } else if (server.data.info.type === 'LOADBALANCER') {
          // console.info(`server.data.info.type LOADBALANCER`);
          
        } else {
          console.info(`unknown server type:`, server.data.info.type);
          
        }
      }
      
    },
    
    async checkLaravel (server, force = false) {
      // console.info(`checklaravel this.enablemonitoring:`, this.enablemonitoring);
      if (!this.enablemonitoring && force === false) return;
      server.health.laravel.status = 'fetching';
      server.health.laravel.duration = '';
      // console.info(`http://${server.data.info.ip}:8080/api/v1/health/laravel`);
      const { duration, data, error } = await this.FetchData(`http://${server.data.info.ip}:9998/api/health/laravel`, 'GET', {});
      server.health.laravel = { duration, response: data, error, status: 'finished' };
    },
    async checkMainLaravel(force = false) {
      if (!this.enablemonitoring && force === false) return;
      
      this.mainhealth.laravel.status = 'fetching';
      this.mainhealth.laravel.duration = '';
      // console.info(`http://${server.data.info.ip}:8080/api/v1/health/laravel`);
      const { duration, data, error } = await this.FetchData(`https://${this.MAINHOST}/api/health/laravel`, 'GET', {});
      this.mainhealth.laravel = { duration, response: data, error, status: 'finished' };
    },
    
    
    async checkDatabase(server, force = false) {
      if (!this.enablemonitoring && force === false) return;
      server.health.database.status = 'fetching';
      server.health.database.duration = '';
      // console.info(`http://${server.data.info.ip}:8080/api/v1/health/database`);
      const { duration, data, error } = await this.FetchData(`http://${server.data.info.ip}:9998/api/health/database`, 'GET', {});
      server.health.database = { duration, response: data, error, status: 'finished' };
    },
    async checkMainDatabase(force = false) {
      if (!this.enablemonitoring && force === false) return;
      
      this.mainhealth.database.status = 'fetching';
      this.mainhealth.database.duration = '';
      // console.info(`http://${server.data.info.ip}:8080/api/v1/health/database`);
      const { duration, data, error } = await this.FetchData(`https://${this.MAINHOST}/api/health/database`, 'GET', {});
      this.mainhealth.database = { duration, response: data, error, status: 'finished' };
    },
    
    
    async checkRedis(server, force = false) {
      if (!this.enablemonitoring && force === false) return;
      server.health.redis.status = 'fetching';
      server.health.redis.duration = '';
      // console.info(`http://${server.data.info.ip}:8080/api/v1/health/database`);
      const { duration, data, error } = await this.FetchData(`http://${server.data.info.ip}:9998/api/health/redis`, 'GET', {});
      server.health.redis = { duration, response: data, error, status: 'finished' };
    },
    async checkMainRedis(force = false) {
      if (!this.enablemonitoring && force === false) return;
      this.mainhealth.redis.status = 'fetching';
      this.mainhealth.redis.duration = '';
      // console.info(`http://${server.data.info.ip}:8080/api/v1/health/database`);
      const { duration, data, error } = await this.FetchData(`https://${this.MAINHOST}/api/health/redis`, 'GET', {});
      this.mainhealth.redis = { duration, response: data, error, status: 'finished' };
    },
    
    async checkQueue(server, force = false) {
      if (!this.enablemonitoring && force === false) return;
      server.health.queue.status = 'fetching';
      server.health.queue.duration = '';
      // console.info(`http://${server.data.info.ip}:8080/api/v1/health/database`);
      const { duration, data, error } = await this.FetchData(`http://${server.data.info.ip}:9998/api/health/queue`, 'GET', {});
      server.health.queue = { duration, response: data, error, status: 'finished' };
    },
    async checkMainQueue(force = false) {
      if (!this.enablemonitoring && force === false) return;
      this.mainhealth.queue.status = 'fetching';
      this.mainhealth.queue.duration = '';
      // console.info(`http://${server.data.info.ip}:8080/api/v1/health/database`);
      const { duration, data, error } = await this.FetchData(`https://${this.MAINHOST}/api/health/queue`, 'GET', {});
      this.mainhealth.queue = { duration, response: data, error, status: 'finished' };
    },
    
    async checkPusher() {
    // async checkPusher(server, force = false) {
      return false;
      // if (!this.enablemonitoring && force === false) return;
      // // console.info(`checkPusher...:`, server);
      // try {
      //   server.health.pusher.status = 'fetching';
      //   server.health.pusher.duration = '';
      //   server.health.pusher.response = '';
      //   server.health.pusher.error = '';
        
      //   const startTime = new Date().getTime();
      //   let rtn = await this.$store.dispatch('monitor/TestPusher', {}); 
      //   // console.info(`checkPusher rtn:`, rtn)
      //   if (!rtn || rtn === false | rtn.stat !== 1) {
      //     // console.info(`eeeeeorroror???1`, rtn);
      //     server.health.pusher.duration = '-';
      //     server.health.pusher.response = '-';
      //     server.health.pusher.status = 'failed';
      //     server.health.pusher.error = 'error';
      //     return;
      //   } else if (rtn.payload.status !== 1) {
      //     // console.info(`eeeeeorroror???2`, rtn);
      //     server.health.pusher.duration = '-';
      //     server.health.pusher.response = rtn.payload.resp;
      //     server.health.pusher.status = 'failed';
      //     server.health.pusher.error = rtn.payload.msg;
      //     return;
      //   }
        
      //   server.health.pusher.duration = rtn.payload.status === 1 ? new Date().getTime() - startTime : '-';
      //   server.health.pusher.response = rtn.payload.resp;
      //   server.health.pusher.status = rtn.payload.status;
      //   server.health.pusher.error = rtn.payload.msg;
      //   return;
      // } catch (err) { 
      //   // console.info('Error TestPusher:', err.message); 
      //   server.health.pusher.duration = '-';
      //   server.health.pusher.response = '-';
      //   server.health.pusher.status = 'failed';
      //   server.health.pusher.error = 'error';
      // }
      
    },
    
    async checkNuxt(server, force = false) {
      if (!this.enablemonitoring && force === false) return;
      try {
        const startTime = new Date().getTime();
        server.health.nuxt.status = 'fetching';
        server.health.nuxt.duration = `-`;
        server.health.nuxt.response = `${this.SITE_ROOT}/`;
      
        let rtn = await this.$store.dispatch('monitor/checknuxtserver', server.data.info.ip);   
        // console.info(`checkNuxt rtn:`, rtn);
        server.health.nuxt.duration = new Date().getTime() - startTime;
        server.health.nuxt.error = '';
        if (rtn.nuxtstatus !== 1) {
          server.health.nuxt.error = "fetch error";
        }
      } catch (err) {
        server.health.nuxt.error = "fetch error";
      }
      server.health.nuxt.status = 'finished';
    },
    
    async checkMainNuxt(force = false) {
      if (!this.enablemonitoring && force === false) return;
      try {
        const startTime = new Date().getTime();
        this.mainhealth.nuxt.status = 'fetching';
        this.mainhealth.nuxt.duration = `-`;
        this.mainhealth.nuxt.response = `${this.SITE_ROOT}/api/`;
      
        let rtn = await this.$store.dispatch('monitor/checknuxtserver', '0.0.0.0');
        // console.info(`checkNuxt rtn:`, rtn);
        this.mainhealth.nuxt.duration = new Date().getTime() - startTime;
        this.mainhealth.nuxt.error = '';
        if (rtn.nuxtstatus !== 1) {
          this.mainhealth.nuxt.error = "fetch error";
        }
      } catch (err) {
        this.mainhealth.nuxt.error = "fetch error";
      }
      this.mainhealth.nuxt.status = 'finished';
    },
    
    async FetchData (url, method = 'GET', data) {
      // console.info(`FetchData:`, url);
      const startTime = new Date().getTime();
      const rtn = {
        startTime,
        duration: 0,
        data: '',
        error: '',
      };

      try {
        const response = await axios_clean({ method, url, data });
        // eslint-disable-next-line no-console
        // console.info('response:', response);

        if (response.status !== 200) {
          // eslint-disable-next-line no-console
          console.info("'failed request, response.status:", response);
          throw new Error('failed request:' + response.status);
        }
        rtn.duration = new Date().getTime() - startTime;
        rtn.data = response.data;
      } catch (err) {
        console.info("'FAAAILLL failed request, response.status:", err);
        rtn.error     = err.message;
        rtn.status    = 0;
        rtn.duration  = 'FAIL';
        rtn.data      = '';
      }

      return rtn;
    },
    
    
    FilterServerData(FilterArr) {
      let rtn = [];
      for(let server of this.serverlist) {
        const searchString = "|" + FilterArr.join('|') + "|";
        // console.info(`searchString:`, searchString);
        if (searchString.indexOf("|" + server.data.info.type + "|") > -1) {
          rtn.push(server);
        }
        
      }
      // console.info(`FilterServerData:`, rtn);
      return rtn;
    },
    
    async ShowPageLoading(maxDuration = 30000){
      this.pageLoading = true;
      
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
      
      this.pageLoadingTimeout = setTimeout(() => {
        this.HidePageLoading();
      }, maxDuration);
    },
    
    async HidePageLoading() {
      this.pageLoading = false;
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
    },
    
    async ToggleServerLBEnabled(ToChangeServer){
      console.info(`ToggleServerLBEnabled:`, ToChangeServer);
      const EnableDisableVal = ToChangeServer.LB_EnabledToggle
      
      this.ShowPageLoading(5000);
      setTimeout(() => { 
        ToChangeServer.LB_EnabledToggle = !ToChangeServer.LB_EnabledToggle
      }, 30);
      
      this.SendToAllLoadBalancerAction('LBEnableDisableServer', 
        JSON.stringify({
          ToChangeServer: ToChangeServer.name, 
          enabled: EnableDisableVal
        })
      );
    },
    
    async SendToAllLoadBalancerAction(action, payload){
      for(let ser of this.serverlist) {
        // console.info(`server:::`, server.data.info.type);
        if (ser.data.info.type === 'LOADBALANCER') {
          // console.info(`sending to: ${ser.name}`, payload);
          await this.$store.dispatch('monitor/FBRequestAction', { 
            server: ser.name, 
            action, 
            payload
          });
          this.ShowPageLoading(500);
        }
      }
    },
    
    ShowEnvFiles(Files) {
      let toPrint = "";
      for(let file of Files.checkfiles) {
        toPrint += "<div>";
        toPrint += file.name + " - " + file.size + " - " + file.checksum;
        toPrint += "</div><br>";
      }
      
      // toPrint += `totalhash: ${Files.totalHashString}<br>`;
      toPrint += `totalhash: ${Files.totalHashStringshort}<br>`;
      
      this.$buefy.dialog.confirm({
          message: toPrint,
      });
    },
    
    
  },
};
</script>

<style scoped>
  .hasError{
    color: red;
  }
  
  .clickable{
    cursor: pointer;
  }
  .clickable:hover{
    color: #AEAEAE;
  }
  
  .itemtext{
    float:left;
    display: inline;
    min-width: 80px;
    font-weight: bold;
    /* background-color: orange;; */
  }
  
  
  .HashStatusOK,
  .GitStatusOK{
    color:darkgreen;
  }
  
  .HashStatusFailed,
  .GitStatusFailed{
    color: red;
  }
  
  .wa-logo{
    float:left;
    clear:both;
    margin-right: 15px; ;
  }
        
  body .wa-color{
    background-color: rgba(214, 221, 253, 1);
  }
  
  .wa-logoTop {
    float:left;
    position: absolute;
    left: 25px;
    top: 100px;
    width: 64px;
    height: 64px; 
  }
</style>